import axios from 'axios';
import { AppContext } from 'context/AppContext';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { invoiceslogin, login } from 'store/authSlice';
import { getHotel, handleHotelsArray } from 'store/chosenHotelsSlice';
import { getInvoices } from 'store/invoicesSlice';
import * as yup from 'yup';
import { handleAlert } from '../functions/handleAlert';
import { getLogs } from '../store/logsSlice';
import AddLog from './AddLog/AddLog';
import AddNewItemForm from './CreateInvoice/AddNewItemForm';
import CreateInvoice from './CreateInvoice/CreateInvoice';
import EditItemForm from './CreateInvoice/EditItemForm';
import DeleteInvoice from './DeleteInvoice/DeleteInvoice';
import DeleteLog from './DeleteLog/DeleteLog';
import DownloadPDFForm from './DownloadPDFForm/DownloadPDFForm';
import EditHotel from './EditHotel/EditHotel';
import EditInvoice from './EditInvoice/EditInvoice';
import EditLog from './EditLog/EditLog';
import LoginForm from './LoginForm/LoginForm';
import SearchForInvoicesForm from './SearchForInvoicesForm/SearchForInvoicesForm';
import SearchForLogsForm from './SearchForLogsForm/SearchForLogsForm';
import SendMailForm from './SendMailForm/SendMailForm';
import InvoicesLoginForm from './InvoicesLoginForm/InvoicesLoginForm';

const server_url = process.env.REACT_APP_SERVER_URL

const Forms = ({ type }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.auth)
  const { log } = useSelector((state) => state.log)
  const { invoice } = useSelector((state) => state.invoice)
  const { chosenHotels, numberOfHotels } = useSelector((state) => state.chosenHotels)
  const { logId, searchForInvoicesQuery, handleCloseDeletePDFModal, searchForLogsQuery, hotelData, hotelIndex, handleCloseDeleteLogModal, handleCloseEditHotelModal, handleCloseSendMailModal, itemsTableRows, handleCloseDownloadLogModal, totalNights, handleAddNewItem, handleCloseAddItemModal, handleEditItem, handleCloseEditItemModal, editableItemData } = useContext(AppContext)
  const navigate = useNavigate()


  //Login
  const loginValidationSchema = yup.object({
    userName: yup
      .string('Enter your username').required("Username is Required"),
    password: yup
      .string('Enter your password').required("password is Required").min(8)
  });

  const loginInitialValues = {
    userName: "",
    password: ""
  }

  const loginFormik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      await axios.post(`${server_url}/login`, values).then((res) => {
        dispatch(login({ token: res.data.token, userName: values.userName }))
        handleAlert(res.data.message, "success")
        navigate(`${process.env.REACT_APP_HOME_ROUTE}`)
      }).catch((err) => {
        try {
          handleAlert(err.response.data.message, "error")
        } catch (error) {
          handleAlert("error occurs", "error")
        }
      })
      setLoading(false)
    },
  });

  const InvoicesloginFormik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      await axios.post(`${server_url}/invoiceslogin`, values).then((res) => {
        dispatch(invoiceslogin({ token: res.data.token, userName: values.userName }))
        handleAlert(res.data.message, "success")
        navigate(`${process.env.REACT_APP_INVOICES_ROUTE}`)
      }).catch((err) => {
        try {
          handleAlert(err.response.data.message, "error")
        } catch (error) {
          handleAlert("error occurs", "error")
        }
      })
      setLoading(false)
    },
  });


  //Search For Logs
  const searchForLogsValidationSchema = yup.object({
    search: yup
      .string('Enter your Search')
  });

  const searchForLogsInitialValues = {
    search: ""
  }

  const searchForLogsFormik = useFormik({
    initialValues: searchForLogsInitialValues,
    validationSchema: searchForLogsValidationSchema,
    onSubmit: (values) => {
      setLoading(true)
      dispatch(getLogs({ count: 0, search: values.search }))
      setLoading(false)
    },
  });


  //Add Log
  const addLogValidationSchema = yup.object({
    name: yup
      .string('Enter Name').required("Name is Required"),
    startDate: yup
      .string('Enter Start Date').required("Start Date is Required"),
    endDate: yup
      .string('Enter End Date').required("End Date is Required"),
    customerName: yup.string('Enter Customer Name').required("Customer Name is Required"),
    customerEmail: yup.string('Enter Customer Email').email("Enter Valid Email").required("Customer Email is Required"),
    subjectLine: yup.string('Enter Subject Line').required("Subject Line is Required"),
    companyName: yup.string('Enter Company Name').required("Company Name is Required"),
    agent: yup.string('Enter Agent Name').required("Agent Name Date is Required"),
    agentNumber: yup.string('Enter Agent Number').required("Agent Number Date is Required"),
  });

  const addLogInitialValues = {
    name: "",
    startDate: "",
    endDate: "",
    customerName: "",
    customerEmail: "",
    subjectLine: "",
    companyName: "",
    agent: "",
    agentNumber: "",
  }

  const addLogFormik = useFormik({
    initialValues: addLogInitialValues,
    validationSchema: addLogValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      let done = true
      let hotels = []
      chosenHotels.map((h, i) => {
        if (h.data === null || h.price === null || !h.currency) {
          if (h.data === null) {
            handleAlert(`Please Choose Hotel ${i + 1}`, "error")
          }
          if (h.price === null) {
            handleAlert(`Please Choose Hotel Price ${i + 1}`, "error")
          }
          if (!h.currency) {
            handleAlert(`Please Choose Currency ${i + 1}`, "error")
          }
          done = false
        } else {
          hotels.push({ Id: h.data._id, Price: h.price, Currency: h.currency })
        }
        return 0;
      })
      if (!done) {
        return
      }
      values.hotels = hotels
      setLoading(true)
      await axios.post(`${server_url}/addPDF`, values, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {
        handleAlert(res.data.message, "success")
        resetForm()
        navigate(`${process.env.REACT_APP_LOG_ROUTE}/${res.data.id}`)
        dispatch(getLogs({ count: 0, search: searchForLogsQuery }))
        dispatch(handleHotelsArray({ numberOfHotels }))
      }).catch((err) => {
        try {
          handleAlert(err.response.data.message, "error")
        } catch (error) {
          handleAlert(error.message, "error")
        }
      })
      setLoading(false)
    },
  });


  //Edit Log
  const editLogValidationSchema = yup.object({
    name: yup
      .string('Enter Name').required("Name is Required"),
    startDate: yup
      .string('Enter Start Date').required("Start Date is Required"),
    endDate: yup
      .string('Enter End Date').required("End Date is Required"),
    customerName: yup.string('Enter Customer Name').required("Customer Name is Required"),
    customerEmail: yup.string('Enter Customer Email').email("Enter Valid Email").required("Customer Email is Required"),
    subjectLine: yup.string('Enter Subject Line').required("Subject Line is Required"),
    companyName: yup.string('Enter Company Name').required("Company Name is Required"),
    agent: yup.string('Enter Agent Name').required("Agent Name Date is Required"),
    agentNumber: yup.string('Enter Agent Number').required("Agent Number Date is Required"),
  });

  const editLogInitialValues = {
    name: "",
    startDate: "",
    endDate: "",
    customerName: "",
    customerEmail: "",
    subjectLine: "",
    companyName: "",
    agent: "",
    agentNumber: "",
  }

  const editLogFormik = useFormik({
    initialValues: editLogInitialValues,
    validationSchema: editLogValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      let done = true
      let hotels = []
      chosenHotels.map((h, i) => {
        if (h.data === null || h.price === null || !h.currency) {
          if (h.data === null) {
            handleAlert(`Please Choose Hotel ${i + 1}`, "error")
          }
          if (h.price === null) {
            handleAlert(`Please Choose Hotel Price ${i + 1}`, "error")
          }
          if (!h.currency) {
            handleAlert(`Please Choose Currency ${i + 1}`, "error")
          }
          done = false
        } else {
          hotels.push({ Id: h.data._id, Price: h.price, Currency: h.currency })
        }
        return 0;
      })
      if (!done) {
        return
      }
      values.hotels = hotels
      setLoading(true)
      await axios.put(`${server_url}/editPDF/${log._id}`, values, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {
        handleAlert(res.data.message, "success")
        navigate(`${process.env.REACT_APP_LOG_ROUTE}/${log._id}`)
        dispatch(getLogs({ count: 0, search: searchForLogsQuery }))
        dispatch(handleHotelsArray({ numberOfHotels }))
        resetForm()
      }).catch((err) => {
        try {
          handleAlert(err.response.data.message, "error")
        } catch (error) {
          handleAlert(error.message, "error")
        }
      })
      setLoading(false)
    },
  });


  //Downalod PDF
  const downloadPDFValidationSchema = yup.object({
    fileName: yup
      .string('Enter File Name').required("File Name is Required"),
  });

  const downloadPDFInitialValues = {
    fileName: "BTB_LOG",
  }

  const downloadPDFFormik = useFormik({
    initialValues: downloadPDFInitialValues,
    validationSchema: downloadPDFValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      await axios.get(`${server_url}/generate-pdf/${logId}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(async (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${values.fileName}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        handleCloseDownloadLogModal()
      }).catch((err) => {
        try {
          handleAlert(err.response.data.message, "error")
        } catch (error) {
          handleAlert(error.message, "error")
        }
      })
      setLoading(false)
    },
  });



  //Create Invoice
  const createInvoiceValidationSchema = yup.object({
    invoiceNumber: yup
      .string('Enter Invoice Number').required("Invoice Number is Required"),
    invoiceTo: yup
      .string('Enter Invoice to Who'),
    customer: yup
      .string('Enter Customer Name').required("Customer Name is Required"),
    company: yup
      .string('Enter Company Name').required("Company Name is Required"),
    address: yup
      .string('Enter Address').required("Address is Required"),
    hotelName: yup
      .string('Enter Hotel Name').required("Hotel Name is Required"),
    hotelAddress: yup
      .string('Enter Hotel Address').required("Hotel Address is Required"),
    hotelPhone: yup
      .string('Enter Hotel Phone').required("Hotel Phone is Required"),
    checkInDate: yup
      .date('Enter Check In Date').required("Check In Date is Required"),
    checkOutDate: yup
      .date('Enter Check out Date').required("Check out Date is Required"),
    modificationDate: yup
      .date('Enter Modification Date').required("Modification Date is Required"),
    remainingBalanceDue: yup
      .date('Enter Remaining Balance Date').required("Remaining Balance Date is Required"),
    taxInPrecent: yup
      .number('Enter Tax in Precent').required("Tax is Required").test('between0And100', 'Tax must be between 0 and 100', (value) => {
        const taxes = parseInt(value, 10);
        return taxes <= 100 && taxes >= 0;
      }),
    totalRooms: yup
      .string('Enter Total Rooms').required("Total Rooms is Required"),
    referenceNumber: yup
      .string('Enter Reference Number').required("Reference Number is Required"),
    currency: yup
      .string('Enter Currency').required("Currency is Required"),
    collectedAmount: yup
      .string('Enter Collected Amount'),
  });

  const createInvoiceInitialValues = {
    invoiceNumber: "",
    invoiceTo: "",
    customer: "",
    company: "",
    address: "",
    currency: "$",
    email: [],
    phone: [],
    hotelName: "",
    hotelAddress: "",
    hotelPhone: "",
    checkInDate: "",
    checkOutDate: "",
    modificationDate: "",
    remainingBalanceDue: "",
    taxInPrecent: "",
    totalRooms: "",
    referenceNumber: "",
    collectedAmount: "",
    penaltyFees: "",
    cutOffDate: "",
    cancellation: "",
    attrition: "",
  }

  const createInvoiceFormik = useFormik({
    initialValues: createInvoiceInitialValues,
    validationSchema: createInvoiceValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (values.phone.length === 0) {
        handleAlert("Enter Phone Number", "error")
        return
      }
      if (values.email.length === 0) {
        handleAlert("Enter Email Number", "error")
        return
      }
      if (itemsTableRows.length === 0) {
        handleAlert("Enter New Item", "error")
        return;
      }
      values.table = itemsTableRows
      values.totalNights = totalNights
      setLoading(true)
      await axios.post(`${server_url}/addForm`, values, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(async (res) => {
        resetForm()
        navigate(`${process.env.REACT_APP_INVOICE_ROUTE}/${res.data.id}`)
        handleAlert(res.data.message, "success")
      }).catch((err) => {
        try {
          handleAlert(err.response.data.message, "error")
        } catch (error) {
          handleAlert(error.message, "error")
        }
      })
      setLoading(false)
    },
  });


  //Add New Item
  const addNewItemValidationSchema = yup.object({
    nights: yup
      .string('Enter Number of Nights').required("Number of Nights is Required"),
    guestName: yup
      .string('Enter Guest Name').required("Guest Name is Required"),
    checkInDate: yup
      .date('Enter Check In Date').required("Check In Date is Required"),
    checkOutDate: yup
      .date('Enter Check out Date').required("Check out Date is Required"),
    smoking: yup
      .string('Enter Smoking Or Not').required("Smoking Status is Required"),
    roomType: yup
      .string('Enter Room Type'),
    bedType: yup
      .string('Enter Bed Type'),
    average: yup
      .number('Enter Average Price Per Night').required("Average Price Per Night is Required").test('moreThan0', 'Tax must be more than 0', (value) => {
        const taxes = parseInt(value, 10);
        return taxes > 0;
      })
  });

  const addNewItemInitialValues = {
    nights: "",
    average: "",
    guestName: "",
    checkInDate: "",
    checkOutDate: "",
    smoking: "Non-Smoking",
    breakfast: false,
    roomType: "",
    bedType: "",
    extras: ""
  }

  const addNewItemFormik = useFormik({
    initialValues: addNewItemInitialValues,
    validationSchema: addNewItemValidationSchema,
    onSubmit: (values) => {
      setLoading(true)
      handleAddNewItem(values)
      handleCloseAddItemModal()
      setLoading(false)
    },
  });


  //Edit Item
  const editItemValidationSchema = yup.object({
    nights: yup
      .string('Enter Number of Nights').required("Number of Nights is Required"),
    guestName: yup
      .string('Enter Guest Name').required("Guest Name is Required"),
    checkInDate: yup
      .date('Enter Check In Date').required("Check In Date is Required"),
    checkOutDate: yup
      .date('Enter Check out Date').required("Check out Date is Required"),
    smoking: yup
      .string('Enter Smoking Or Not').required("Smoking Status is Required"),
    roomType: yup
      .string('Enter Room Type'),
    bedType: yup
      .string('Enter Bed Type'),
    average: yup
      .number('Enter Average Price Per Night').required("Average Price Per Night is Required").test('moreThan0', 'Tax must be more than 0', (value) => {
        const taxes = parseInt(value, 10);
        return taxes > 0;
      })
  });

  const editItemInitialValues = {
    nights: editableItemData && editableItemData.nights,
    average: editableItemData && editableItemData.average,
    guestName: editableItemData && editableItemData.guestName,
    checkInDate: editableItemData && editableItemData.checkInDate,
    checkOutDate: editableItemData && editableItemData.checkOutDate,
    smoking: editableItemData && editableItemData.smoking,
    breakfast: editableItemData && editableItemData.breakfast,
    roomType: editableItemData && editableItemData.roomType,
    bedType: editableItemData && editableItemData.bedType,
    extras: editableItemData && editableItemData.extras
  }

  const editItemFormik = useFormik({
    initialValues: editItemInitialValues,
    validationSchema: editItemValidationSchema,
    onSubmit: (values) => {
      setLoading(true)
      handleEditItem(values)
      handleCloseEditItemModal()
      setLoading(false)
    },
  });


  //Edit Invoice
  const editInvoiceValidationSchema = yup.object({
    invoiceNumber: yup
      .string('Enter Invoice Number').required("Invoice Number is Required"),
    invoiceTo: yup
      .string('Enter Invoice to Who'),
    customer: yup
      .string('Enter Customer Name').required("Customer Name is Required"),
    company: yup
      .string('Enter Company Name').required("Company Name is Required"),
    address: yup
      .string('Enter Address').required("Address is Required"),
    hotelName: yup
      .string('Enter Hotel Name').required("Hotel Name is Required"),
    hotelAddress: yup
      .string('Enter Hotel Address').required("Hotel Address is Required"),
    hotelPhone: yup
      .string('Enter Hotel Phone').required("Hotel Phone is Required"),
    checkInDate: yup
      .date('Enter Check In Date').required("Check In Date is Required"),
    checkOutDate: yup
      .date('Enter Check out Date').required("Check out Date is Required"),
    modificationDate: yup
      .date('Enter Modification Date').required("Modification Date is Required"),
    remainingBalanceDue: yup
      .date('Enter Remaining Balance Date').required("Remaining Balance Date is Required"),
    taxInPrecent: yup
      .number('Enter Tax in Precent').required("Tax is Required").test('between0And100', 'Tax must be between 0 and 100', (value) => {
        const taxes = parseInt(value, 10);
        return taxes <= 100 && taxes >= 0;
      }),
    totalRooms: yup
      .string('Enter Total Rooms').required("Total Rooms is Required"),
    referenceNumber: yup
      .string('Enter Reference Number').required("Reference Number is Required"),
    currency: yup
      .string('Enter Currency').required("Currency is Required"),
    collectedAmount: yup
      .string('Enter Collected Amount'),
  });

  const editInvoiceInitialValues = {
    invoiceNumber: "",
    invoiceTo: "",
    customer: "",
    company: "",
    address: "",
    currency: "$",
    email: [],
    phone: [],
    hotelName: "",
    hotelAddress: "",
    hotelPhone: "",
    checkInDate: "",
    checkOutDate: "",
    modificationDate: "",
    remainingBalanceDue: "",
    taxInPrecent: "",
    totalRooms: "",
    referenceNumber: "",
    collectedAmount: ""
  }

  const editInvoiceFormik = useFormik({
    initialValues: editInvoiceInitialValues,
    validationSchema: editInvoiceValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (values.phone.length === 0) {
        handleAlert("Enter Phone Number", "error")
        return
      }
      if (values.email.length === 0) {
        handleAlert("Enter Email Number", "error")
        return
      }
      if (itemsTableRows.length === 0) {
        handleAlert("Enter New Item", "error")
        return;
      }
      values.table = itemsTableRows
      values.totalNights = totalNights
      setLoading(true)
      await axios.put(`${server_url}/editForm/${invoice._id}`, values, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(async (res) => {
        resetForm()
        navigate(`${process.env.REACT_APP_INVOICE_ROUTE}/${res.data.data._id}`)
        handleAlert(res.data.message, "success")
      }).catch((err) => {
        try {
          handleAlert(err.response.data.message, "error")
        } catch (error) {
          handleAlert(error.message, "error")
        }
      })
      setLoading(false)
    },
  });


  //Edit Hotel
  const editHotelValidationSchema = yup.object({
    description: yup
      .string('Enter Description').required("Description is Required"),
  });

  const editHotelInitialValues = {
    description: hotelData && hotelData.description,
  }

  const editHotelFormik = useFormik({
    initialValues: editHotelInitialValues,
    validationSchema: editHotelValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      await axios.put(`${server_url}/editHotel/${hotelData._id}`, values, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {
        handleAlert(res.data.message, "success")
        handleCloseEditHotelModal()
        dispatch(getHotel({ id: hotelData._id, index: hotelIndex }))
      }).catch((err) => {
        try {
          handleAlert(err.response.data.message, "error")
        } catch (error) {
          handleAlert(error.message, "error")
        }
      })
      setLoading(false)
    },
  });


  //Search For Invoices
  const searchForInvoicesValidationSchema = yup.object({
    search: yup
      .string('Enter your Search')
  });

  const searchForInvoicesInitialValues = {
    search: ""
  }

  const searchForInvoicesFormik = useFormik({
    initialValues: searchForInvoicesInitialValues,
    validationSchema: searchForInvoicesValidationSchema,
    onSubmit: (values) => {
      setLoading(true)
      dispatch(getInvoices({ count: 0, search: values.search }))
      setLoading(false)
    },
  });

  //Delete Log
  const handleDeleteLog = async (e) => {
    e.preventDefault()
    setLoading(true)
    await axios.delete(`${server_url}/deletePDF/${logId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      handleAlert(res.data.message, "success")
      handleCloseDeleteLogModal()
      dispatch(getLogs({ count: 0, search: searchForLogsQuery }))
    }).catch((err) => {
      try {
        handleAlert(err.response.data.message, "error")
      } catch (error) {
        handleAlert(error.message, "error")
      }
    })
    setLoading(false)
  }

  //Delete Invoice
  const handleDeleteInvoice = async (e) => {
    e.preventDefault()
    setLoading(true)
    await axios.delete(`${server_url}/deletePDF/${logId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      handleAlert(res.data.message, "success")
      handleCloseDeletePDFModal()
      dispatch(getInvoices({ count: 0, search: searchForInvoicesQuery }))
    }).catch((err) => {
      try {
        handleAlert(err.response.data.message, "error")
      } catch (error) {
        handleAlert(error.message, "error")
      }
    })
    setLoading(false)
  }

  //Send Mail
  const handleSendMail = async (e) => {
    e.preventDefault()
    setLoading(true)
    await axios.post(`${server_url}/sendEmail/${logId}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      handleAlert(res.data.message, "success")
      handleCloseSendMailModal()
    }).catch((err) => {
      try {
        handleAlert(err.response.data.message, "error")
      } catch (error) {
        handleAlert(error.message, "error")
      }
    })
    setLoading(false)
  }


  return (
    <form onSubmit={type === "send_mail" ? handleSendMail : type === "search_for_logs" ? searchForLogsFormik.handleSubmit : type === "search_for_invoices" ? searchForInvoicesFormik.handleSubmit : type === "delete_log" ? handleDeleteLog : type === "delete_invoice" ? handleDeleteInvoice : type === "add_log" ? addLogFormik.handleSubmit : type === "edit_log" ? editLogFormik.handleSubmit : type === "login" ? loginFormik.handleSubmit : type === "invoicesLogin" ? InvoicesloginFormik.handleSubmit : type === "download_pdf" ? downloadPDFFormik.handleSubmit : type === "edit_hotel" ? editHotelFormik.handleSubmit : type === "create_invoice" ? createInvoiceFormik.handleSubmit : type === "edit_invoice" ? editInvoiceFormik.handleSubmit : type === "add_new_item" ? addNewItemFormik.handleSubmit : type === "edit_item" && editItemFormik.handleSubmit} style={{ width: "100%" }}>
      {
        type === "search_for_logs" ? <SearchForLogsForm loading={loading} formik={searchForLogsFormik} /> : type === "search_for_invoices" ? <SearchForInvoicesForm loading={loading} formik={searchForInvoicesFormik} /> : type === "delete_log" ? <DeleteLog loading={loading} /> : type === "delete_Invoice" ? <DeleteInvoice loading={loading} /> : type === "add_log" ? <AddLog loading={loading} formik={addLogFormik} /> : type === "edit_log" ? <EditLog loading={loading} formik={editLogFormik} /> : type === "login" ? <LoginForm formik={loginFormik} loading={loading} /> :type === "invoicesLogin" ? <InvoicesLoginForm formik={InvoicesloginFormik} loading={loading} /> : type === "send_mail" ? <SendMailForm loading={loading} /> : type === "edit_hotel" ? <EditHotel loading={loading} formik={editHotelFormik} /> : type === "download_pdf" ? <DownloadPDFForm loading={loading} formik={downloadPDFFormik} /> : type === "create_invoice" ? <CreateInvoice formik={createInvoiceFormik} loading={loading} /> : type === "edit_invoice" ? <EditInvoice formik={editInvoiceFormik} loading={loading} /> : type === "add_new_item" ? <AddNewItemForm loading={loading} formik={addNewItemFormik} /> : type === "edit_item" && <EditItemForm loading={loading} formik={editItemFormik} />
      }
    </form>
  )
}

export default Forms