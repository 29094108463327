import { ContentCopyRounded, DeleteRounded, EditRounded } from "@mui/icons-material";
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, tableCellClasses } from "@mui/material";
import { styled } from '@mui/material/styles';
import { AppContext } from "context/AppContext";
import { formatNumberWithCommas } from "functions/formateNumber";
import { handleDate } from "functions/handleDate";
import { useContext } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ItemsTable = ({ collectedAmount , taxInPrecent, currency, readOnly,penaltyFees }) => {
  const { itemsTableRows, handleDuplicate, handleOpenEditItemModal, handleDeleteItem } = useContext(AppContext)
  if (!collectedAmount){
    collectedAmount = 0
  }
  
  const totalBalance = itemsTableRows.reduce((acc, row) => {
    const tax = (+taxInPrecent / 100) * row.average;
    const total = ((+row.average + tax) * +row.nights);
    return acc + total;
  }, 0);
  const refundAmount = collectedAmount -  totalBalance  ;  
  // console.log(refundAmount)
  const noWrapText = { wordBreak: "normal !important", textWrap: "nowrap !important" }

  return itemsTableRows.length > 0 && (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow >
            <StyledTableCell>
              <Typography variant="h6" className={"fw700"} >Items</Typography>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ maxWidth: "100%" }}>
              <Typography variant="h6" className={"fw700"} >Description</Typography>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Typography variant="h6" sx={{ wordBreak: "keep-all" }} className={"fw700"} >Tax & Fees Per Night</Typography>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Typography variant="h6" sx={{ wordBreak: "keep-all" }} className={"fw700"} >Average Price Per Night</Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography variant="h6" className={"fw700"} >Total</Typography>
            </StyledTableCell>
            {!readOnly && <StyledTableCell align="right">
              <Typography variant="h6" className={"fw700"} >Action</Typography>
            </StyledTableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {itemsTableRows.map((row, i) => {
            const tax = (+taxInPrecent / 100) * row.average
            const total = ((+row.average + tax) * +row.nights)
            return (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row" >
                  <Typography variant="h6" sx={noWrapText}>{`${row.nights} ${row.nights > 1 ? "nights" : "night"}`}</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Box className={`grid jcc aic g5`}>
                    <Typography variant="h6" className="fw600" >{row.guestName}</Typography>
                    <Typography variant="h6" >({handleDate(row.checkInDate)} - {handleDate(row.checkOutDate)})</Typography>
                    <Typography variant="h6" className="main_color">{row.roomType && `${row.roomType} Room - `}{row.bedType && `${row.bedType} - `} {row.smoking}{row.breakfast && ` - Breakfast`}</Typography>
                    <Typography variant="h6" >{row.extras}</Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="h6" sx={noWrapText}> {formatNumberWithCommas(tax.toFixed(2), currency) || "null"}</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="h6" sx={{ wordBreak: "normal !important", textWrap: "nowrap !important" }}>{formatNumberWithCommas(row.average.toFixed(2), currency) || "null"}</Typography>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Typography variant="h6" sx={noWrapText}>{formatNumberWithCommas(total.toFixed(2), currency)}</Typography>
                </StyledTableCell>
                {!readOnly && <StyledTableCell align="right" >
                  <Box className={"flex jcfe aic"}>
                    <Tooltip title={"Duplicate"}>
                      <IconButton onClick={() => handleDuplicate(i)}>
                        <ContentCopyRounded sx={{ color: (theme) => theme.palette.primary.main }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Edit"}>
                      <IconButton onClick={() => handleOpenEditItemModal(row, i)}>
                        <EditRounded sx={{ color: (theme) => theme.palette.primary.main }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delete"}>
                      <IconButton onClick={() => handleDeleteItem(i)}>
                        <DeleteRounded sx={{ color: (theme) => theme.palette.youtube }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </StyledTableCell>}
              </StyledTableRow>
            )
          })}

          <TableRow>
            <TableCell colSpan={4}>
              <Typography variant="h6" className="fw700" >Total Balance</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h6" sx={noWrapText}> {formatNumberWithCommas(totalBalance.toFixed(2), currency)}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>
              <Typography variant="h6" className="fw700" >Collected Amount</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography sx={noWrapText} variant="h6"> {collectedAmount ? formatNumberWithCommas(collectedAmount.toFixed(2), currency) : "0.00"}</Typography>
            </TableCell>
            
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>
              <Typography variant="h6" className="fw700" >Refund Amount</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography sx={noWrapText} variant="h6"> {refundAmount > 0 ? formatNumberWithCommas(refundAmount.toFixed(2), currency) : "0.00"}</Typography>
            </TableCell>
            </TableRow>
            <TableRow>
            <TableCell colSpan={4}>
              <Typography variant="h6" className="fw700" >Penalty Fees</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography sx={noWrapText} variant="h6"> {penaltyFees > 0 ? formatNumberWithCommas(penaltyFees.toFixed(2), currency) : "0.00"}</Typography>
            </TableCell>
            </TableRow>
            
         
          <TableRow>
            <TableCell colSpan={4}>
              <Typography variant="h6" className="fw700" >Remaining Balance</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography sx={noWrapText} variant="h6">{(parseFloat(totalBalance) - parseFloat(collectedAmount)) > 0 ?formatNumberWithCommas((parseFloat(totalBalance) - parseFloat(collectedAmount)).toFixed(2), currency) : "0.00"}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ItemsTable