import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'

export const getInvoices = createAsyncThunk("invoices/getInvoices",async(args)=>{
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_COOKIE_NAME}`)
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/listForms?page=${args.count}&search=${args.search}`,{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  return {data : res.data , count : args.count}
}) 

const initialState = {
  invoices: [],
  totalInvoices: 0,
  isLoading:true,
  count:0,
}

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoices.fulfilled, (state, { payload }) => {
      state.invoices = payload.data.data
      state.totalInvoices = payload.data.totalInvoices
      state.isLoading = false
      state.count = payload.count
    })
    builder.addCase(getInvoices.rejected, (state) => {
      state.isLoading = true
    })
  },
})


export default invoicesSlice.reducer