import { Box, Typography } from "@mui/material"
import InvoiceBoxData from "components/InvoiceBoxData/InvoiceBoxData"
import InvoiceButtons from "components/InvoiceButtons/InvoiceButtons"
import ItemsTable from "components/ItemsTable/ItemsTable"

const InvoiceSection = ({ invoice }) => {
  const dataBoxesClasses = `grid jcsb aifs g20`
  return (
    <Box className={`grid jcs aic g30`}>
      <Typography variant="h4" className={`tac fw700`} sx={{ color: (theme) => theme.palette.primary.main }}>{`Invoice # ${invoice.invoiceNumber}`}</Typography>

      <Box className={`grid jcs aic g20`}>
        <Box className={dataBoxesClasses} sx={{ gridTemplateColumns: "repeat(2,1fr)" }}>
          <InvoiceBoxData k={"Invoice To"} val={invoice.invoiceTo} />
          <InvoiceBoxData k={"Customer"} val={invoice.customer} />
        </Box>

        <Box className={dataBoxesClasses} sx={{ gridTemplateColumns: "repeat(2,1fr)" }}>
          <InvoiceBoxData k={"Company"} val={invoice.company} />
          <InvoiceBoxData k={"Address"} val={invoice.address} />
        </Box>

        <Box className={dataBoxesClasses} sx={{ gridTemplateColumns: "repeat(2,1fr)" }}>
          <InvoiceBoxData k={invoice.email.length > 1 ? "Emails" : "Email"} val={invoice.email.join(" , ")} />
          <InvoiceBoxData k={invoice.phone.length > 1 ? "Phones" : "Phone"} val={invoice.phone.join(" , ")} />
        </Box>

        <Box className={dataBoxesClasses} sx={{ gridTemplateColumns: "repeat(2,1fr)" }}>
          <InvoiceBoxData k={"Hotel Name"} val={invoice.hotelName} />
          <InvoiceBoxData k={"Hotel Address"} val={invoice.hotelAddress} />
        </Box>

        <Box className={dataBoxesClasses} sx={{ gridTemplateColumns: "repeat(2,1fr)" }}>
          <InvoiceBoxData k={"Hotel Phone"} val={invoice.hotelPhone} />
          <InvoiceBoxData k={"Total Rooms"} val={`${invoice.totalRooms} Rooms`} />
        </Box>

        <Box className={dataBoxesClasses} sx={{ gridTemplateColumns: "repeat(2,1fr)" }}>
          <InvoiceBoxData k={"Check In Date"} val={invoice.checkInDate} />
          <InvoiceBoxData k={"Check Out Date"} val={new Date(invoice.checkOutDate).toISOString().split("T")[0]} />
        </Box>

        <Box className={dataBoxesClasses} sx={{ gridTemplateColumns: "repeat(2,1fr)" }}>
          <InvoiceBoxData k={"Modification Date"} val={new Date(invoice.modificationDate).toISOString().split("T")[0]} />
          <InvoiceBoxData k={"Remaining Balance Due"} val={invoice.remainingBalanceDue} />
        </Box>

        <Box className={dataBoxesClasses} sx={{ gridTemplateColumns: "repeat(2,1fr)" }}>
          <InvoiceBoxData k={"Tax in Precent Per Night"} val={invoice.taxInPrecent} />
          <InvoiceBoxData k={"Collected Amount"} val={invoice.collectedAmount} />
        </Box>

        <Box className={dataBoxesClasses} sx={{ gridTemplateColumns: "repeat(2,1fr)" }}>
          <InvoiceBoxData k={"Currency"} val={invoice.currency} />
          <InvoiceBoxData k={"Reference Number"} val={invoice.referenceNumber} />
        </Box>
        <Box className={dataBoxesClasses} sx={{ gridTemplateColumns: "repeat(2,1fr)" }}>
          <InvoiceBoxData k={"Attrition"} val={invoice.attrition} />
          <InvoiceBoxData k={"Cancellation"} val={invoice.cancellation} />
        </Box>
        <InvoiceBoxData k={"Cut Off Date"} val={invoice.cutOffDate && new Date(invoice.cutOffDate).toISOString().split("T")[0]} />
        <InvoiceBoxData k={"Penalty Fees"} val={invoice.penaltyFees} />



      </Box>

      <ItemsTable collectedAmount={invoice.collectedAmount} penaltyFees={invoice.penaltyFees} taxInPrecent={invoice.taxInPrecent} currency={invoice.currency} readOnly={true} />

      <InvoiceButtons invoice={invoice} />
    </Box>
  )
}

export default InvoiceSection