import { Box, IconButton, Typography } from "@mui/material"
import { AppContext } from "context/AppContext"
import { useContext } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Link, useLocation, useParams } from "react-router-dom"
import logo from "../../assets/images/logo/icon.png"
import styles from "./Logo.module.scss"

const Logo = () => {
  const { pathname } = useLocation()
  const { id } = useParams()
  const { invocieRoutes } = useContext(AppContext)

  const handleLogolink = () => {
    if (pathname !== `${process.env.REACT_APP_HOTEL_ROUTE}/${id}`) {
      if (invocieRoutes.includes(pathname)) {
        return `${process.env.REACT_APP_INVOICES_ROUTE}`
      } else {
        return `${process.env.REACT_APP_HOME_ROUTE}`
      }
    }
    return "https://btbintl.com/"
  }

  return (
    <Box className={`flex jcc aic ${styles.logo_box}`}>
      <Link to={handleLogolink()} className={`flex jcc aife ${styles.logo}`}>
        <IconButton className={`${styles.logo_button}`}>
          <LazyLoadImage src={logo} alt={"logo"} />
        </IconButton>
      </Link>
      <Typography variant={"h6"} className="main_color fw700">{invocieRoutes.includes(pathname) || pathname ===`${process.env.REACT_APP_INVOICES_LOGIN_ROUTE}` ? "Invoices" : "Hotel Options"}</Typography>
    </Box>
  )
}

export default Logo