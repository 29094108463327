import { AddRounded, ChevronLeftRounded, DescriptionRounded, HotelRounded, LoginRounded, LogoutRounded } from "@mui/icons-material"
import { AppBar, Box, Typography } from "@mui/material"
import { AppContext } from "context/AppContext"
import { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useParams } from "react-router-dom"
import { logout } from "store/authSlice"
import { PrimaryButton } from "../../mui/PrimaryButton"
import { PrimaryContainer } from "../../mui/PrimaryContainer"
import Logo from "../Logo/Logo"
import styles from "./Header.module.scss"

const Header = () => {
  const { handleOpenChooseNumberOfHotelModal, invocieRoutes } = useContext(AppContext)
  const { isSigned } = useSelector((state) => state.auth)
  const { pathname } = useLocation()
  const { id } = useParams()
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
  }

  const handleSchedule = () => {
    window.location.href = "https://www.btbintl.com/schedule-a-call"
  }
  // console.log(pathname);
  // console.log(invocieRoutes.includes(pathname));
  return (
    <AppBar>
      <PrimaryContainer className={`flex jcsb aic g30 ${styles.header_contain}`}>
        <Logo />
        {pathname !== `${process.env.REACT_APP_HOTEL_ROUTE}/${id}` ?
          (isSigned ?
            (
              <Box className={`flex jcc aic g10`}>
                {
                  pathname === `${process.env.REACT_APP_HOME_ROUTE}` || pathname === `${process.env.REACT_APP_INVOICES_ROUTE}` ? (invocieRoutes.includes(pathname) ? <Link to={`${process.env.REACT_APP_CREATE_INVOICE_ROUTE}`}>
                    <PrimaryButton className={`flex jcc aic g5`}>
                      <AddRounded />
                      <Typography variant="h6">Add</Typography>
                    </PrimaryButton></Link> : <PrimaryButton onClick={handleOpenChooseNumberOfHotelModal} className={`flex jcc aic g5`}>
                    <AddRounded />
                    <Typography variant="h6">Add</Typography>
                  </PrimaryButton>) :
                    <Link to={`${invocieRoutes.includes(pathname) ? process.env.REACT_APP_INVOICES_ROUTE : process.env.REACT_APP_HOME_ROUTE}`}>
                      <PrimaryButton className={`flex jcc aic g5`}>
                        <ChevronLeftRounded />
                        <Typography variant="h6">Back Home</Typography>
                      </PrimaryButton>
                    </Link>
                }
                
                <Link to={ pathname.startsWith(`/invoice`) ? `${process.env.REACT_APP_INVOICES_LOGIN_ROUTE}`: `${process.env.REACT_APP_LOGIN_ROUTE}`}>
                  <PrimaryButton onClick={handleLogout} className={`flex jcc aic g5`}>
                    <LogoutRounded />
                    <Typography variant="h6">Logout</Typography>
                  </PrimaryButton>
                </Link>
              </Box>
            )
            : (
              <Link to={`${process.env.REACT_APP_LOGIN_ROUTE}`}>
                
              </Link>
            )
          ) :
          (
            <PrimaryButton onClick={handleSchedule}>
              Schedule a Call
            </PrimaryButton>
          )
        }
      </PrimaryContainer>
    </AppBar>
  )
}

export default Header